import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function DuengemittelsubstratProduktPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Produkte: Düngemittelsubstrat" description="Phosphor ist ein für die Landwirtschaft unerlässlicher Mineraldünger und findet darüber hinaus auch in vielen technischen Bereichen Verwendung." />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Produkte</h2>
              <p>Düngemittelsubstrat - Phosphor-Rückgewinnung aus Klärschlamm</p>
            </header>

            <p>
              Phosphor ist ein für die Landwirtschaft unerlässlicher Mineraldünger und findet darüber hinaus auch in vielen technischen Bereichen Verwendung.
            </p>

            <div className="image featured"><Image name="soil" /></div>
            
            <p>
              In Deutschland fallen pro Jahr ca. 2 Millionen Tonnen Klärschlamm (Trockensubstanz TS ca. 25 %) an. Diese teilentwässerten Klärschlämme müssen aufwendig und teuer entsorgt werden. Gemäß einer Veröffentlichung des Bundesumweltamtes wurden 2010 ca. 30 % der Menge auf landwirtschaftlichen Flächen ausgebracht, ca. 53 % in unterschiedlichen Anlagen verbrannt und ca. 17 % in den Landschaftsbau verbracht.
            </p>
            <p>
              Die entstehenden Kosten auf der Seite der Klärwerke sind dabei enorm: Die Gesamtkosten inkl. der Kosten für die Teilentwässerung und Transport liegen zwischen 120 € und 375 € je Tonne TS bei der landwirtschaftlichen Ausbringung und zwischen 180 € und 400 € je Tonne TS bei der thermischen Klärschlamm-Monoverbrennung.
            </p>
            <p>
              Die hohen Kosten, insbesondere bei der thermischen Verwertung, resultieren maßgeblich aus dem hohen Wassergehalt des Klärschlamms, seinem damit verbundenen verhältnismäßig geringen Heizwert und seinen sehr schlechten Entwässerungseigenschaften. Diese Kostenstruktur wird sich in den nächsten Jahren voraussichtlich noch drastisch verschärfen, da die Aufbringung von Klärschlamm auf Flächen des ökologischen Landbaus mittlerweile verboten ist und die im Herbst 2017 in Kraft getretene Klärschlammverordnung die Ausbringung von Klärschlamm auf Äckern aus Umweltschutzgründen eindämmt. Auch der Einsatz von Düngemitteln wird auf der Seite der landwirtschaftlichen Abnehmer durch die Düngeverordnung drastisch eingeschränkt. Dies alles sorgt dafür, dass die Nachfrage nach Klärschlammentsorgung rapide ansteigt.
            </p>
            <p>
              EU-weit müssen aktuell ca. 9 Millionen Tonnen (TS) Klärschlamm jährlich entsorgt werden. Da nicht genügend Verbrennungsanlagen vorhanden sind, ziehen unweigerlich die Entsorgungspreise an. Es geht bereits so weit, dass Kommunen ihren Schlamm nicht mehr loswerden.
            </p>

            <div className="row features" style={{ marginTop: 50, marginBottom: 100 }}>
              <section className="col-6 col-12-narrower feature">
                <header>
                  <h4>Klärschlamm-Entsorgung wird spürbar teurer</h4>
                  <p style={{ fontSize: '1em' }}>Sächsische Zeitung, 11.8.2017</p>
                </header>
                <p>
                  Der Grund sind Änderungen in der Düngemittel- und Klärschlammverordnung. Nach der dürfen Klärschlämme jetzt nicht mehr so ohne Weiteres auf die Felder aufgebracht werden wie in der Vergangenheit. „Daher wachsen die Lager und das LAV weiß mit den neuen Lieferungen nicht mehr wohin.“
                </p>
              </section>
              <section className="col-6 col-12-narrower feature">
                <header>
                  <h4>Preise für Klärschlammentsorgung steigen rasch weiter</h4>
                  <p style={{ fontSize: '1em' }}>15.06.2018 Hannoversche Allgemeine</p>
                </header>
                <p>
                  Die Entsorgung von Klärschlamm aus dem Gruppenklärwerk in Nordgoltern wird immer schwieriger. Nur wenige Unternehmen nehmen die Rückstände überhaupt noch an, die Kosten dafür steigen rasant.
                </p>
                <p>
                  Die freien Entsorgungskapazitäten auf dem Markt sind „sehr schnelllebig“, sagte der Betriebsleiter der Stadtentwässerung, Torsten Holzhausen. Es gebe mittlerweile Kommunen, „die gar keine Angebote mehr bekommen“.
                </p>
              </section>
            </div>

            <p>
              Durch die Verlagerung der Entsorgung in die Verbrennung statt in die Düngung fallen nun zusätzlich riesige Mengen von klimaschädigendem CO2 an, da ein bilanzieller Ausgleich kurzfristig nicht zu erwarten ist. Abhilfe kann deshalb die teilweise Verkohlung des Klärschlamms bringen, da die Kohle als natürliche CO2-Senke gilt.
            </p>
            <p>
              Etwa die Hälfte des deutschen Bedarfs an Mineraldüngerphosphor könnte aus dem in Klärschlämmen und Abwässern enthaltenem Phosphor gedeckt werden und so die Abhängigkeit von ausländischen Phosphorproduzenten reduzieren.
            </p>


            <h3>Die Lösung</h3>

            <ul>
              <li>
                DEZENTRALE TECHNOLOGIE: Der Klärschlamm wird dort verwertet, wo er anfällt. Das heißt: Das von uns eingesetzte Verfahren ist als dezentrale, „vor Ort“- Technologie ausgelegt. Die Anlagen rechnen sich auch für kleine Kläranlagen. Je nach Bedarf kann jede Anlage aufgrund der Containerlösung modular erweitert werden.
              </li>
              <li>
                UNABHÄNGIGKEIT: Das Klärwerk ist künftig nicht mehr von Kapazitätsengpässen sowie steigenden Preisen für Transport und Entsorgung betroffen. Es kann seine Klärschlammverwertung nunmehr gezielt kalkulieren und langfristig planen und steuern.
              </li>
              <li>
                VOLLSTÄNDIGE VERWERTUNG: Wir lösen dieses eklatante Problem mit kompakten Anlagen, in denen Klärschlamm stofflich und energetisch vollständig zu einem europaweit zugelassenen Phosphordünger-Substrat verwertet wird. Eine weitere, aufwendige Aufbereitung oder die Deponierung von „Reststoffen“ entfällt.
              </li>
            </ul>

            <h3>Die Vorteile</h3>

            <p>Mit der Karbonisierung von Klärschlamm schützen wir aktiv unsere Umwelt und das Klima</p>

            <ul>
              <li>
                Die Klärschlammmenge wird deutlich reduziert
              </li>
              <li>
                Phosphor wird als Sekundärrohstoff in pflanzenverfügbarer Form zurück gewonnen. Da bei diesem Verfahren die thermische Behandlung bei deutlich geringeren Temperaturen erfolgt als bei der normalen Verbrennung, bleibt der Phosphor – im Gegensatz zu den Aschen der Monoverbrennungsanlagen – pflanzenverfügbar. Es ist kein nachträglicher und aufwendiger Aufschluss notwendig.
              </li>
              <li>
                Bei der Karbonisierung wird der Kohlenstoff langfristig gebunden und über Jahrhunderte im Boden gespeichert
              </li>
              <li>
                Der CO2-Ausstoß gegenüber der Monoverbrennung wird drastisch reduziert.
              </li>
              <li>
                Der Klärschlamm wird hygienisiert. Hormone, Keime, Arzneimittelrückstände, Mikroplastik oder ähnliches werden eliminiert
              </li>
              <li>
                Der Verwertungsprozess verläuft autotherm. Für den Betrieb der Anlage wird bis auf die Startenergie ausschließlich Energie aus dem Klärschlamm verwendet.
              </li>
            </ul>

            <div className="image featured"><Image name="salad" /></div>

            <p>Die neue Klärschlamm-Verordnung führt das von uns angewendete Karbonisierungsverfahren als eine mögliche Verwertungs-Technologie aus Klärschlamm auf:</p>

            <ul>
              <li>
                Offizielle Nennung des Karbonisierungsverfahrens als einsetzbares Phosphor-Recyclingverfahren
              </li>
              <li>
                Explizite Definition der Karbonisate als Produkt der Klärschlammverwertung
              </li>
              <li>
                Gleichwertige Einstufung des Karbonisierungs-Verfahrens mit dem Klärschlammverbrennungs-Verfahren
              </li>
              <li>
                Karbonisaten stehen alle Behandlungs- und Verwertungswege offen: Zur Phosphor-Rückgewinnung, Verwertung oder Aufbereitung
              </li>
              <li>
                Karbonisierung ist zusammen mit den Verbrennungsverfahren von der halbjährlichen Analytik-Pflicht ausgenommen
              </li>
              <li>
                Zudem hat der Bundesrat die Regierung dazu aufgefordert, die Zulassung von Sekundär-Phosphaten als Düngemittel zu beschleunigen.
              </li>
            </ul>
          </article>

        </div>
      </div>
    </Layout >
  )
}

function Image({ name }) {
  const result = useStaticQuery(graphql`
    query {
      salad: file(relativePath: { eq: "markus-spiske-1387238-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 1200) { ...GatsbyImageSharpFluid } }
      }

      soil: file(relativePath: { eq: "gabriel-jimenez-241711-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 1200) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={result[name].childImageSharp.fluid} />
}
